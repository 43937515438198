export default {
  setStateProperty (state, options) {
    state[options.property] = options.value
  },
  showModal (state, modal) {
    state.modals[modal] = true
  },
  hideModal (state, modal) {
    state.modals[modal] = false
  },
  setCurrentUser (state, val) {
    console.log('Set Current User')
    console.log(val)
    state.currentUser = val
  },
  setUserProfile (state, val) {
    state.userProfile = val
  }
}
