import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import 'firebase/compat/storage'
import 'firebase/compat/auth'

// firebase init goes here
const config = {
  apiKey: 'AIzaSyC1ynzi7dtxFkie6PGesQWInLni-EeALYY',
  authDomain: 'wine-geo.firebaseapp.com',
  projectId: 'wine-geo',
  storageBucket: 'wine-geo.appspot.com',
  messagingSenderId: '1015462089771',
  appId: '1:1015462089771:web:d8ae4be611086f1570c66e',
  measurementId: 'G-1WZPL1FBSL'
}
firebase.initializeApp(config)

// firebase utils
const db = firebase.firestore()
const storage = firebase.storage()
const auth = firebase.auth()
const currentUser = auth.currentUser
const Timestamp = firebase.firestore.Timestamp

// date issue fix according to firebase
const settings = {
}
db.settings(settings)

// firebase collections
const usersCollection = db.collection('users')
const geographiesCollection = db.collection('geographies')
const footprintsCollection = db.collection('footprints')
const compileGeographiesRequestsCollection = db.collection('compileGeographiesRequests')

export {
  db,
  storage,
  auth,
  currentUser,
  usersCollection,
  geographiesCollection,
  footprintsCollection,
  compileGeographiesRequestsCollection,
  Timestamp
}
