<template>
  <div id="admin">
    <AdminNav />
    <GeographiesTable />
    <CreateGeographies v-if="$store.state.modals.createGeographies" />
  </div>
</template>

<script>
import AdminNav from './AdminNav.vue'
import CreateGeographies from './CreateGeographies.vue'
import GeographiesTable from './GeographiesTable.vue'

export default {
  components: {
    AdminNav,
    CreateGeographies,
    GeographiesTable
  }
}
</script>
