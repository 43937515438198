<template>
  <div id="home">
    <HeroText />
    <p class="text-center"><a href="https://orbitist.com" target="_blank">© Orbitist LLC</a></p>
  </div>
</template>

<script>
import HeroText from './HeroText.vue'

export default {
  components: {
    HeroText
  }
}
</script>
