<template>
  <div id="heroText" class="mb-5">
    <div class="text-center mt-5 pt-5 container">
      <h1 class="mb-5">Wine Geographer</h1>
      <h2>A map of wine regions around the world.</h2>
      <h3>(Coming soon)</h3>
      <b-btn
        pill
        size="lg"
        variant="outline-success"
        class="mt-5 mb-5"
        @click="showSignUp = true"
      >Get Notified When We Launch</b-btn>
    </div>
    <div
      class="o-modal"
      v-if="showSignUp"
    >
      <div class="o-modal-content">
        <div class="o-modal-header">
          <a
            @click="showSignUp = false"
            class="close clickable"
          ><i class="far fa-times"></i></a>
          <p class="lead">Stay Informed</p>
        </div><!-- /.o-modal-header -->
        <div class="o-modal-body">
          <iframe class="airtable-embed" src="https://airtable.com/embed/shrYiEE2LLNaZ4cSZ?backgroundColor=green" frameborder="0" onmousewheel="" width="100%" height="533" style="background: transparent; border: 1px solid #ccc;"></iframe>
        </div><!-- /.o-modal-body -->
      </div><!-- /.o-modal-content -->
    </div><!-- /.o-modal -->
  </div><!-- /#hero-text -->
</template>

<script>
export default {
  data () {
    return {
      showSignUp: false
    }
  }
}
</script>
