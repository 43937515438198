<template>
  <div id="chat">
    <h1>{{ status }} ({{ geographies.length }} geographies)</h1>
    <button v-if="!running" @click="start()">Start!</button>
    <div v-if="chatMessage">
      <div v-html="chatMessage"></div>
    </div>
    <div v-if="error"><p>Error: {{ error }}</p></div>
    <ul>
      <li
        v-for="(geography, i) in geographies"
        v-bind:key="i"
      >{{ geography.name }}<span v-html="geography.description"></span></li>
    </ul>
  </div>
</template>

<script>
import axios from 'axios'
const markdown = require('markdown').markdown
const fb = require('../../firebaseConfig.js')

export default {
  data () {
    return {
      // prompt: 'Tell me about the Napa Valley wine region.',
      chatMessage: false,
      error: false,
      status: 'Waiting to run',
      running: false,
      geographies: [],
      geography: {}
    }
  },
  async created () {
    // QUERY HERE!!
    const snapshot = await fb.geographiesCollection.get()
    const geographies = []
    snapshot.forEach(doc => {
      const geography = doc.data()
      geography.id = doc.id
      geographies.push(geography)
    })
    // sort the geographies array in alphabetical order based on the 'name' property
    this.geographies = geographies.sort((a, b) => a.name.localeCompare(b.name))
  },
  methods: {
    start () {
      this.running = true
      this.getGeography()
    },
    getGeography () {
      this.geographies.forEach(geo => {
        if (!geo.description || (geo.description && geo.description.length < 10)) {
          this.geography = geo
        }
      })
      if (this.geographies.length > 0 && this.geography.name) {
        this.requestChat()
      } else {
        this.status = 'Waiting to Run'
        this.running = false
      }
    },
    async requestChat () {
      console.log(this.geography)
      const prompt = 'Tell me about the ' + this.geography.name + ' wine region in ' + this.geography.country + '.'
      this.status = prompt
      const postObject = {
        chatPrompt: prompt
      }
      await axios.post('https://us-central1-wine-geo.cloudfunctions.net/api/chat', postObject).then(response => {
        this.chatMessage = markdown.toHTML(response.data.content)
        this.status = 'Got Response, adding to geography...'
        this.saveGeography()
      }).catch(error => {
        this.error = error.toString()
        console.error(error)
        console.log('Restarting...')
        this.start()
      })
    },
    saveGeography () {
      fb.geographiesCollection.doc(this.geography.id).update({
        updatedOn: new Date(),
        updatedBy: this.$store.state.currentUser.uid,
        description: this.chatMessage
      }).then(() => {
        this.status = 'Updated Geography!'
        for (let i = this.geographies.length - 1; i >= 0; i--) {
          if (this.geographies[i].id === this.geography.id) {
            this.geographies.splice(i, 1)
          } else if (this.geographies[i].description && this.geographies[i].description.length > 10) {
            this.geographies.splice(i, 1)
          }
        }
        setTimeout(() => {
          this.start()
        }, 500)
      })
    }
  }
}
</script>
