import Vue from 'vue'
import Vuex from 'vuex'
import actions from './actions.js'
import mutations from './mutations.js'
const fb = require('../firebaseConfig.js')

Vue.use(Vuex)

export const store = new Vuex.Store({
  state: {
    currentUser: null,
    modals: {
      createGeographies: false
    },
    geographyProperties: [
      {
        label: 'Aka',
        machine_name: 'aka',
        type: 'Text'
      },
      {
        label: 'Approved Maps',
        machine_name: 'approved_maps',
        type: 'Text'
      },
      {
        label: 'AVA ID',
        machine_name: 'ava_id',
        type: 'Text',
        description: 'UC Davis dataset id for avas.'
      },
      {
        label: 'Boundary Description',
        machine_name: 'boundary_description',
        type: 'Text'
      },
      {
        label: 'County',
        machine_name: 'county',
        type: 'Text'
      },
      {
        label: 'Country',
        machine_name: 'country',
        type: 'Text'
      },
      {
        label: 'CFR Author',
        machine_name: 'cfr_author',
        type: 'Text',
        description: 'UC Davis dataset name of the TTB employee that authored the proposed and/or final rules in the Federal Register; typically found near the end of the document.'
      },
      {
        label: 'CFR Index',
        machine_name: 'cfr_index',
        type: 'Text',
        description: 'UC Davis dataset number code that indicates where the final rule document can be found; typically, it is in the section heading of the CFR entry online.'
      },
      {
        label: 'CFR Revision History',
        machine_name: 'cfr_revision_history',
        type: 'Text',
        description: 'UC Davis dataset string of text citing the documents that currently apply to this boundary definition.'
      },
      {
        label: 'Description',
        machine_name: 'description',
        type: 'Text'
      },
      {
        label: 'Established',
        machine_name: 'established',
        type: 'Date and Time'
      },
      {
        label: 'Library of Congress Subject Heading',
        machine_name: 'lcsh',
        type: 'Text',
        description: 'UC Davis dataset. Generally UCD Library staff has filled these in and will add them as new subject headings are approved.'
      },
      {
        label: 'Name',
        machine_name: 'name',
        type: 'Text'
      },
      {
        label: 'Petitioner',
        machine_name: 'petitioner',
        type: 'Text'
      },
      {
        label: 'Removed',
        machine_name: 'removed',
        type: 'Date and Time'
      },
      {
        label: 'Sources',
        machine_name: 'sources',
        type: 'Text'
      },
      {
        label: 'State',
        machine_name: 'state',
        type: 'Text'
      },
      {
        label: 'Used Maps',
        machine_name: 'used_maps',
        type: 'Text',
        description: 'UC Davis dataset field describing maps actually used in the creation of the GIS boundary.'
      },
      // START EUROPE
      {
        label: 'PDO ID',
        machine_name: 'pdoId',
        type: 'Text'
      },
      {
        label: 'PDO Wine Category',
        machine_name: 'pdo_wine_category',
        type: 'Text'
      },
      {
        label: 'PDO Varieties',
        machine_name: 'pdo_varieties',
        type: 'Text'
      },
      {
        label: 'PDO Other Varieties',
        machine_name: 'pdo_other_varieties',
        type: 'Text'
      },
      {
        label: 'PDO Max Yield HL',
        machine_name: 'pdo_max_yield_hl',
        type: 'Text'
      },
      {
        label: 'PDO Max Yield KG',
        machine_name: 'pdo_max_yield_kg',
        type: 'Text'
      },
      {
        label: 'PDO Min Planting Density',
        machine_name: 'pdo_min_planting_density',
        type: 'Text'
      },
      {
        label: 'PDO Irrigation',
        machine_name: 'pdo_irrigation',
        type: 'Text'
      },
      {
        label: 'PDO Amendment',
        machine_name: 'pdo_amendment',
        type: 'Text'
      },
      {
        label: 'PDO Info',
        machine_name: 'pdo_info',
        type: 'Text'
      },
      {
        label: 'PDO Municipalities',
        machine_name: 'pdo_municipalities',
        type: 'Text'
      },
      // START AUSTRALIA
      {
        label: 'GI Altitude High',
        machine_name: 'gi_altitude_high',
        type: 'Text'
      },
      {
        label: 'GI Altitude Low',
        machine_name: 'gi_altitude_low',
        type: 'Text'
      },
      {
        label: 'GI Annual Rain July June',
        machine_name: 'gi_annual_rain_july_june',
        type: 'Text'
      },
      {
        label: 'GI Ave Crush',
        machine_name: 'gi_ave_crush',
        type: 'Text'
      },
      {
        label: 'GI Ave Climate Period',
        machine_name: 'gi_ave_climate_period',
        type: 'Text'
      },
      {
        label: 'GI Created By',
        machine_name: 'gi_created_by',
        type: 'Text'
      },
      {
        label: 'GI Ave Crush Period',
        machine_name: 'gi_ave_crush_period',
        type: 'Text'
      },
      {
        label: 'GI Area KM2',
        machine_name: 'gi_area_km2',
        type: 'Text'
      },
      {
        label: 'GI Number',
        machine_name: 'gi_number',
        type: 'Text'
      },
      {
        label: 'GI Type',
        machine_name: 'gi_type',
        type: 'Text'
      },
      {
        label: 'GI Grow Degree Days Oct Apr',
        machine_name: 'gi_grow_degree_days_oct_apr',
        type: 'Text'
      },
      {
        label: 'GI Season Rain Oct Apr',
        machine_name: 'gi_season_rain_oct_apr',
        type: 'Text'
      },
      {
        label: 'GI Mean Jan Temp',
        machine_name: 'gi_mean_jan_temp',
        type: 'Text'
      },
      {
        label: 'GI More Info',
        machine_name: 'gi_more_info',
        type: 'Text'
      },
      {
        label: 'GI South Latitude',
        machine_name: 'gi_south_latitude',
        type: 'Text'
      },
      {
        label: 'GI URL',
        machine_name: 'gi_url',
        type: 'Text'
      },
      {
        label: 'GI Vine Area Ha',
        machine_name: 'gi_vine_area_ha',
        type: 'Text'
      }
    ],
    userProfile: {}
  },
  mutations: mutations,
  actions: actions,
  modules: {
  }
})

// handle page reload
fb.auth.onAuthStateChanged(user => {
  if (user) {
    store.commit('setCurrentUser', user)
    store.dispatch('fetchUserProfile')
    fb.usersCollection.doc(user.uid).onSnapshot(doc => {
      store.commit('setUserProfile', doc.data())
    })
  }
})
