<template>
  <div id="geographiesTable" class="container mt-2">
    <p>{{ geographies.length }} Geographies</p>
    <b-form-input
      v-model="search"
      placeholder="Search"
      class="mb-2"
    ></b-form-input>
    <b-table :items="filteredGeographies" :fields="fields" @row-clicked="showModal">
      <template #cell(edit)="data">
        <b-button @click.stop="showModal(data.item)">Edit</b-button>
      </template>
    </b-table>

    <b-modal ref="modalRef" title="Edit Geography" @ok="updateGeography" v-show="editingGeography">
      <b-form-group
        v-for="field in validGeographyProperties"
        :key="field.machine_name"
        :label="field.label"
        label-for="field.machine_name"
        :description="field.description"
        label-cols-sm="3"
        label-align-sm="right"
        label-size="sm"
      >
        <b-form-input
          :type="field.type === 'Date and Time' ? 'datetime-local' : 'text'"
          :id="field.machine_name"
          v-model="formFields[field.machine_name]"
          size="sm"
        ></b-form-input>
      </b-form-group>
    </b-modal>
  </div>
</template>

<script>
const fb = require('../../firebaseConfig.js')

export default {
  data () {
    return {
      geographies: [],
      fields: [
        { key: 'name', label: 'Name' },
        { key: 'edit', label: '' }
      ],
      editingGeography: null,
      formFields: {},
      search: ''
    }
  },
  async created () {
    const snapshot = await fb.geographiesCollection.get()
    const geographies = []
    snapshot.forEach(doc => {
      const geography = doc.data()
      geography.id = doc.id
      geographies.push(geography)
    })
    // sort the geographies array in alphabetical order based on the 'name' property
    this.geographies = geographies.sort((a, b) => a.name.localeCompare(b.name))
  },
  computed: {
    filteredGeographies () {
      return this.geographies.filter(
        geo => geo.name.toLowerCase().includes(this.search.toLowerCase())
      )
    },
    validGeographyProperties () {
      if (!this.editingGeography) {
        return []
      }
      return this.$store.state.geographyProperties.filter(
        (property) => Object.prototype.hasOwnProperty.call(this.editingGeography, property.machine_name)
      )
    }
  },
  methods: {
    convertFirestoreTimestampToString (timestamp) {
      if (!timestamp) return ''

      const date = timestamp.toDate() // This converts Firestore Timestamp to a Javascript Date
      return date.toISOString().substring(0, 16) // This formats the date as 'YYYY-MM-DDTHH:mm', which can be used in datetime-local input
    },
    async showModal (item) {
      this.editingGeography = { ...item }
      this.formFields = { ...item }
      for (const field of this.validGeographyProperties) {
        if (field.type === 'Date and Time') {
          this.formFields[field.machine_name] = this.convertFirestoreTimestampToString(this.editingGeography[field.machine_name])
        }
      }
      this.$refs.modalRef.show()
    },
    async updateGeography () {
      const geographyToUpdate = { ...this.formFields }

      // convert any 'Date and Time' strings back to Firestore Timestamps
      for (const field of this.validGeographyProperties) {
        if (field.type === 'Date and Time') {
          const newDate = new Date(geographyToUpdate[field.machine_name])
          if (isNaN(newDate)) {
            console.error(`Invalid date for field ${field.machine_name}: ${geographyToUpdate[field.machine_name]}`)
          } else {
            geographyToUpdate[field.machine_name] = fb.Timestamp.fromDate(newDate)
          }
        }
      }
      geographyToUpdate.updatedOn = new Date()
      geographyToUpdate.updatedBy = this.$store.state.currentUser.uid
      await fb.geographiesCollection.doc(geographyToUpdate.id).update(geographyToUpdate)
      this.geographies = this.geographies.map(geo => geo.id === geographyToUpdate.id ? geographyToUpdate : geo)
      this.$refs.modalRef.hide()
      this.editingGeography = null
    }
  }
}
</script>
