<template>
  <div id="adminNav">
    <div class="container mt-2">
      <b-btn
        @click="$store.commit('showModal', 'createGeographies')"
      >Create Geographies</b-btn>
      <b-btn
        @click="compileGeographies"
        class="ml-2"
      >Compile Geographies</b-btn>
    </div><!-- /.container -->
  </div><!-- /#adminNav -->
</template>

<script>
const fb = require('../../firebaseConfig.js')

export default {
  methods: {
    compileGeographies () {
      fb.compileGeographiesRequestsCollection.add({
        complete: false,
        createdBy: this.$store.state.currentUser.uid,
        createdOn: new Date()
      }).then(doc => {
        const requestId = doc.id
        fb.compileGeographiesRequestsCollection.doc(requestId).onSnapshot(updatedDoc => {
          const updatedRequest = updatedDoc.data()
          updatedRequest.id = updatedDoc.id
          if (updatedRequest.complete) {
            alert('Compile Complete!')
          }
        })
      })
    }
  }
}
</script>
