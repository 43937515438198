<template>
  <div id="createGeographies" class="o-modal">
    <div class="o-modal-content full">
      <div class="o-modal-header">
        <a class="close clickable" @click="$store.commit('hideModal', 'createGeographies')">
          <i class="far fa-times"></i>
        </a>
        <p class="lead">Create Geographies</p>
      </div>
      <div class="o-modal-body">
        <p><strong>Select GeoJSON Files</strong></p>
        <b-form-file :files="selectedFiles" multiple @input="handleFiles" accept=".geojson,.json"></b-form-file>
        <div v-for="(property, index) in geographyProperties" :key="index">
          <label>{{ property.label }}</label>
          <b-form-select v-model="globalMappings[property.machine_name]" :options="[...getAvailableProperties, 'Custom']"></b-form-select>
          <b-form-input v-if="globalMappings[property.machine_name] === 'Custom'" v-model="customMappings[property.machine_name]" placeholder="Enter custom value"></b-form-input>
        </div>
        <table class="table">
          <thead>
            <tr>
              <th>Filename</th>
              <th v-for="(property, index) in geographyProperties" :key="index">
                {{ property.label }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(file, index) in files" :key="index">
              <td>{{ file.name }}</td>
              <td v-for="(property, index) in geographyProperties" :key="index">
                <b-form-select v-model="file.mappings[property.machine_name]" :options="[...file.availableProperties, 'Custom']"></b-form-select>
                <b-form-input v-if="file.mappings[property.machine_name] === 'Custom'" v-model="file.customMappings[property.machine_name]" placeholder="Enter custom value"></b-form-input>
              </td>
            </tr>
          </tbody>
        </table>
        <b-button @click="createGeographies">Create Geographies</b-button>
        <!-- Display progress -->
        <p>Progress: {{progress}}/{{files.length}}</p>
        <b-progress :value="progress" :max="files.length" show-progress animated></b-progress>
      </div>
    </div>
  </div>
</template>

<script>
const fb = require('../../firebaseConfig.js')
export default {
  data () {
    return {
      selectedFiles: [],
      files: [],
      // properties: ['name', 'established', 'state', 'county', 'aka', 'removed', 'petitioner'],
      globalMappings: {
        name: '',
        established: ''
      },
      progress: 0,
      customMappings: {}
    }
  },
  computed: {
    geographyProperties: {
      get () {
        return this.$store.state.geographyProperties
      },
      set (value) {
        this.$store.commit('setStateProperty', { property: 'geographyProperties', value: value })
      }
    },
    getAvailableProperties () {
      let allProperties = []
      for (const file of this.files) {
        allProperties = [...allProperties, ...file.availableProperties]
      }
      return [...new Set(allProperties)]
    }
  },
  watch: {
    globalMappings: {
      handler (newMappings) {
        for (const file of this.files) {
          file.mappings = Object.assign({}, newMappings)
        }
      },
      deep: true
    },
    customMappings: {
      handler (newCustomMappings) {
        for (const file of this.files) {
          file.customMappings = Object.assign({}, newCustomMappings)
        }
      },
      deep: true
    },
    files: {
      handler () {
        this.$forceUpdate()
      },
      deep: true
    }
  },
  methods: {
    async handleFiles (newFiles) {
      this.selectedFiles = newFiles
      const files = await Promise.all(
        this.selectedFiles.map(file => {
          return new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.onload = e => {
              const geojson = JSON.parse(e.target.result)
              const availableProperties = Object.keys(geojson.features[0].properties)
              const mappings = Object.assign({}, this.globalMappings)
              // Save properties to apply them in createGeographies
              const properties = geojson.features.map(feature => feature.properties)
              // Strip properties from GeoJSON features
              geojson.features.forEach(feature => {
                feature.properties = {}
              })
              // Delete unwanted properties from GeoJSON
              delete geojson.name
              delete geojson.crs
              resolve({
                name: file.name,
                geojson: geojson,
                availableProperties: availableProperties,
                mappings: mappings,
                properties: properties,
                customMappings: {}
              })
            }
            reader.onerror = reject
            reader.readAsText(file)
          })
        })
      )
      this.files = files
    },
    async createGeographies () {
      for (const file of this.files) {
        const geography = {
          createdOn: new Date(),
          updatedOn: new Date(),
          createdBy: this.$store.state.currentUser.uid,
          updatedBy: this.$store.state.currentUser.uid
        }
        for (const [key, value] of Object.entries(file.mappings)) {
          let val
          if (value === 'Custom') {
            // If the value is 'Custom', get the custom value from `customMappings`
            val = file.customMappings[key]
          } else {
            // Otherwise, get the value from properties as before
            val = file.properties[0][value]
          }

          // Access properties saved in handleFiles
          let isDateTime = false
          this.geographyProperties.forEach(geographyProperty => {
            if (geographyProperty.machine_name === key && geographyProperty.type === 'Date and Time') {
              isDateTime = true
            }
          })
          if (isDateTime && val) {
            const dateStr = val
            try {
              const timestamp = new Date(dateStr)
              if (isNaN(timestamp.getTime())) {
                console.error(`Unable to parse date: ${dateStr}`)
              } else {
                geography[key] = timestamp
              }
            } catch (err) {
              console.error(`Error parsing date ${dateStr}: ${err}`)
            }
          } else {
            geography[key] = val
          }
        }

        // Add check for undefined values
        for (const key in geography) {
          if (geography[key] === undefined) {
            console.error(`Undefined value detected in field '${key}'. Replacing with null.`)
            geography[key] = null // replace undefined with null
          }
        }

        // Add geography to Firestore and get its ID
        const geographyRef = await fb.geographiesCollection.add(geography)
        const geographyId = geographyRef.id
        console.log(this.$store.state)

        const footprint = {
          geojson: JSON.stringify(file.geojson),
          createdOn: new Date(),
          updatedOn: new Date(),
          createdBy: this.$store.state.currentUser.uid,
          updatedBy: this.$store.state.currentUser.uid,
          // Add the geography ID to the footprint
          geographyId: geographyId
        }

        // Add footprint to Firestore
        await fb.footprintsCollection.add(footprint)
        this.progress += 1
        // Delay of 200 milliseconds between each write to avoid overloading Firestore.
        await new Promise(resolve => setTimeout(resolve, 200))
      }
      alert('All documents have been created.')
    }
  }
}
</script>
